//////////////////////////////////////////////////
//
// Theme names
//
//////////////////////////////////////////////////

$DEFAULT: 'DEFAULT';
$KNECT365: 'KNECT365';
$FANEXPOHQ: 'FANEXPOHQ';
$ASIATECHX: 'DEFAULT'; // Uses Default's theme settings
$ASIATECHXSG: 'ASIATECHXSG';
$ONEOFAKIND: 'ONEOFAKIND';
$INTERIORDESIGN: 'INTERIORDESIGN';
$ARTTORONTO: 'ARTTORONTO';
$THEARTISTPROJECT: 'THEARTISTPROJECT';
$NAJAHSTACK: 'NAJAHSTACK';
$MEFCC: 'MEFCC';

//////////////////////////////////////////////////
//
// Grid setup
//
//////////////////////////////////////////////////

$gutter-width: 20px;

$mobile-breakpoint: 320px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;
$large-desktop-breakpoint: 1200px;

$tablet-plus-breakpoint: 860px;
$desktop-medium-breakpoint: 1015px;
$desktop-plus-breakpoint: 1166px;
$ultra-wide-breakpoint: 1920px;

$extended-layout-width: 1440px;

//////////////////////////////////////////////////
//
// Responsive utils
//
//////////////////////////////////////////////////

$embed-responsive-aspect-ratios: (
  (32 9),
  (21 9),
  (16 9),
  (16 10),
  (4 3),
  (4, 1),
  (2, 1),
  (1 1)
);

//////////////////////////////////////////////////
//
// Stream colors
//
//////////////////////////////////////////////////

$streamColors: (
  null: #999999,
  0: #de3350,
  1: #5448d2,
  2: #2ad1bb,
  3: #ee7a04,
  4: #b2d732,
  5: #5b6384,
  6: #e27fcf,
  7: #400077,
  8: #154ed4,
  9: #70cb14,
  10: #89c3d9,
  11: #7014cb,
  12: #037c68,
  13: #b792fc,
  14: #00b9f9,
  15: #7dd990,
  16: #9c0035,
  17: #0ba1aa,
  18: #09535b,
  19: #333c40,
  20: #1b54a8,
  21: #bc2e06,
  22: #f2a812,
  23: #0098b4,
  24: #333132,
  25: #074d70,
  26: #476d9f,
  27: #761aa3,
  28: #de3350,
  29: #d7142d,
  30: #e44311,
  31: #ee7a04,
  32: #f5c823,
  33: #b2d732,
  34: #70cb14,
  35: #037c68,
  36: #0f7c84,
  37: #7dd990,
  38: #14cb8e,
  39: #2ad1bb,
  40: #00cbcb,
  41: #89c3d9,
  42: #6699cb,
  43: #148ecb,
  44: #0768a3,
  45: #154ed4,
  46: #5448d2,
  47: #5221a2,
  48: #7014cb,
  49: #8f48d2,
  50: #af29cb,
  51: #d964c4,
  52: #d73d94,
  53: #5b6384,
  54: #5b6369,
  55: #9c0035,
  56: #bc2e06,
  57: #ee4d0c,
  58: #f2a812,
  59: #7ab01c,
  60: #51911f,
  61: #1c4f4a,
  62: #09535b,
  63: #009669,
  64: #008c7d,
  65: #0ba1aa,
  66: #0098b4,
  67: #4183be,
  68: #476d9f,
  69: #1b54a8,
  70: #074d70,
  71: #092864,
  72: #3b1096,
  73: #33004e,
  74: #4d0e94,
  75: #761aa3,
  76: #a543ab,
  77: #972d5d,
  78: #373a5a,
  79: #333c40,
  80: #333132,
  81: #00b9f9,
  82: #b792fc,
  83: #ff71de,
  84: #aeb4c4,
  85: #31aca4,
  86: #203b89,
  87: #ffce36,
  88: #72246c,
  89: #000000,
  90: #f0b323,
  91: #41b6e6,
  92: #48a23f,
  93: #dc6b2f,
  94: #3a5dae,
  95: #00857d,
  96: #397685,
  97: #003146,
  98: #1f6e85,
  99: #3b2e79,
  100: #092850,
  101: #db3640,
  102: #303012,
  103: #8faa13,
  104: #cdc72c,
  105: #1a428a,
  106: #6cccb9,
  107: #3d4543,
  108: #202945,
  109: #e0592a,
  110: #df592a,
  111: #00953b,
  112: #231f20,
  113: #cc9f53,
  114: #0061fb,
  115: #0ddfa7,
  116: #960096,
  117: #007c46,
  118: #b01261,
  119: #5bd2e3,
  120: #44ccff,
  121: #f70056,
  122: #090d44,
  123: #a10000,
  124: #67c928,
  125: #0032a3,
  126: #ffcd00,
  127: #e9151d,
  128: #787fb7,
  129: #00a3b5,
  130: #0e44e8,
  131: #ffc700,
  132: #bdd34b,
  133: #343a40,
  134: #ff0000,
  135: #8000ff,
  136: #cf561d,
  137: #25a4a4,
  138: #f50d20,
  139: #0872ba,
  140: #11a7d9,
  141: #002244,
  142: #cc31c2,
  143: #0e6bff,
  144: #00d01a,
  145: #414042,
  146: #0094a6,
  147: #472372,
  148: #180c26,
  149: #9b5de5,
  150: #58595b,
  151: #3f4041,
  152: #73a334,
  153: #8c67fb,
  154: #f7931e,
  155: #ffffff,
  156: #e9ecef,
  157: #e32421,
  158: #727271,
  159: #4d4d4f,
  160: #eda900,
  161: #0083ca,
  162: #00998e,
  163: #8ca8f2,
  164: #4da77d,
  165: #a155ae,
  166: #a888ab,
  167: #a4b670,
  168: #87acf6,
  169: #7187d7,
  170: #4cb67c,
  171: #636466,
  172: #30302f,
  173: #ff4539,
  174: #38b86f,
  175: #d1a21c,
  176: #183562,
  177: #6ea9db,
  178: #ff6600,
  179: #1166bb,
  180: #00b5cb,
  181: #bea0e6,
  182: #232323,
  183: #96d69b,
  184: #efa964,
  185: #ffca57,
  186: #005e6e,
  187: #bde5e1,
  188: #bb84a5,
  189: #564596,
  190: #172138,
  191: #252d43,
  192: #2d3a54,
  193: #7e06ff,
  194: #0066ff,
  195: #000b4a,
  196: #33ccff,
  197: #9c9c45,
  198: #f96c23,
  199: #d230f9,
  200: #3eb149,
  201: #db0059,
  202: #00beab,
  203: #fcc70c,
  204: #012d80,
  205: #c00000,
  206: #2f5597,
  207: #ec7b23,
  208: #5d6385,
  209: #5d646a,
  210: #ac36c5,
  211: #173d69,
  212: #474849,
  213: #de3351,
  214: #cde0b3,
  215: #d63d42,
  216: #0b6094,
  217: #525a5c,
  218: #1f52a5,
  219: #509219,
  220: #f79622,
  221: #012d36,
  222: #007d7c,
  223: #f14900,
  224: #b4bfa3,
  225: #f69b9a,
  226: #8d93bc,
  227: #4da15d,
  228: #14214a,
  229: #e69718,
  230: #470643,
  231: #00ff00,
  232: #00cc9b,
  233: #a67ad2,
  234: #8c1736,
  235: #807f83,
  236: #58162d,
  237: #5c82bd,
  238: #5c6369,
  239: #05ff80,
  240: #d9b8d7,
  241: #4c6645,
  242: #4842c0,
  243: #ff6e19,
  244: #e21b52,
  245: #5bcbf5,
  246: #1a1720,
  247: #dd4826,
  248: #c29764,
  249: #66043a,
  250: #bca468,
  251: #286167,
  252: #191143,
  253: #f15d27,
  254: #193662,
  255: #404041,
  256: #91b961,
  257: #260051,
  258: #6e009c,
  259: #7069b0,
  260: #c2ab1d,
  261: #f9f1e1,
  262: #b9d9db,
  263: #110e41,
  264: #061830,
  265: #f04dff,
) !default;
